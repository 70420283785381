<template>
<div class="login-page">
    <div class="container h-100">
        <p class="main-title"><img src="../../src/assets/images/88sportlogo.png" alt=""></p>
        <div class="row h-100 align-items-center">
            <div class="col-md-12">
                <div class="card-body login-card-body">
                    <a class="back-btn" @click="$router.go(-1)"><img src="../../src/assets/images/svg/left-arrow.svg" alt=""></a>
                    <div class="login-header"><h class="login-heading">Register</h></div>
                    
                    <div class="form-section">
                        <div class="errors" v-if="errors.length>0">
                            <div v-for="(error,index) in errors" :key="index">
                                <p class=" error">{{error}}</p>
                            </div>
                        </div>
                        <form @submit.prevent="register(form)" class="login-form">
                            <div class="input-group mb-3">
                                <input v-model="form.name" type="text" required class="form-control" placeholder="Nickname">
                                <div class="input-group-append">
                                    <span class="input-group-text"><img class="login-email-icon" src="../../src/assets/images/svg/name.svg" alt=""></span>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <input required v-model="form.email" type="email" class="form-control" placeholder="Email">
                                <div class="input-group-append">
                                    <span class="input-group-text"><img class="login-email-icon" src="../../src/assets/images/svg/email.svg" alt=""></span>
                                </div>
                                <p class="input-description">Required for verification & cashout</p>
                            </div>
                            <div class="input-group mb-3">
                                <input required type="file" accept="image/png,image/jpeg" id="image" ref="image" @change="onChangeFileUpload($event)" class="form-control" placeholder="Image">
                                <div class="input-group-append">
                                    <span class="input-group-text"><img class="login-email-icon" src="../../src/assets/images/svg/image.svg" alt=""></span>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <input required v-model="form.password" :type="password_type1" class="form-control" placeholder="Password">
                                <div class="input-group-append">
                                    <span class="input-group-text"><img class="login-password-icon" @click="passwordToggle1()" src="../../src/assets/images/svg/eye.svg" alt=""></span>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <input required v-model="form.password_confirmation" :type="password_type2" class="form-control" placeholder="Confirm Password">
                                <div class="input-group-append">
                                    <span class="input-group-text"><img class="login-password-icon" @click="passwordToggle2()" src="../../src/assets/images/svg/eye.svg" alt=""></span>
                                </div>
                            </div>
                            <span class="forgot-password-link">
                                <router-link to="/forgot-password">Forgot Password?</router-link>
                            </span>
                            <div class="form-group">
                                <button type="submit" class="btn w-100 btn-login">Register</button>
                            </div>
                        </form>
                        <div class="registration-section">
                            <router-link to="/login">Login</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "RegisterPage",

    mounted() {

        if (this.$route.query.uid) {
            this.refer_id = this.$route.query.uid;
            document.cookie = "refer_id=" + this.refer_id;
        } else
            this.refer_id = this.$cookie.get('refer_id');

        if (this.$cookie.get('registeredUser'))
            this.refer_id = '';

    },

    data() {
        return {
            password_type1:'password',
            password_type2:'password',
            refer_id: '',
            image:"",
            errors:[],
            form: {
                name: "",
                email: "",
                password: '',
                password_confirmation: ''
            },
            processing: false
        }
    },
    methods: {
        onChangeFileUpload(event) {
            this.image = this.$refs.image.files[0];
        },

        passwordToggle1(){
            this.password_type1 = this.password_type1=='password'?'text':'password';
        },
        passwordToggle2(){
            this.password_type2 = this.password_type2=='password'?'text':'password';
        },

        register(form) {
            let formData = new FormData();
            formData.append("name",form.name);
            formData.append("email",form.email);
            formData.append("password",form.password);
            formData.append("password_confirmation",form.password_confirmation);
            formData.append("image",this.image);
            formData.append("refer_id",this.refer_id);

            this.$http.post(this.$baseUrl + 'api/register', formData).then(function (response) {

                if(response.data.status)
                    this.$router.push('/login');
                else if(response.data.status==false)
                {
                    this.errors = Object.values(response.data.data);
                    this.errors = this.errors.flat();
                }
                
            }.bind(this)).catch(function (data) {
                alert(data.message)
            }).finally(() => {
                this.processing = false
            })
        },

    }
}
</script>

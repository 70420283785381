<template lang="eng">
<div class="home-page">
    <Header headerType="profile"></Header>
    <b-container fluid="sm">
        <div class="div pt-3">
            <div v-if="errors" class="error">{{ errors }}</div>
            <div class="statistic-section">
                

                <div class="statistic-section-title">
                    <h3>My Statistics</h3>
                </div>
                <div class="statistic-section-setting">
                    <span style="cursor:pointer" @click="showDatePicker=!showDatePicker"><img class="fluid" style="cursor:pointers" src="../assets/images/svg/settingstati.svg" alt="icon"></span>
                    <span>{{ dateDifference() }}</span>

                </div>
            </div>
            <div v-if="showDatePicker" class="date-range-picker">
                <!-- <Datepicker class="w-100" v-model="selectedDate" range lang="eng"/> -->
                <div id="statistics-filter-form">
                    <div id="dates">
                        <div class="form-group">
                            <label for="sdate">Start Date</label>
                            <input v-model="sdate" type="date" required class="form-control">
                        </div>
                        <div class="form-group ml-1">
                            <label for="edate">End Date</label>
                            <input v-model="edate" type="date" required class="form-control">
                        </div>
                    </div>

                    <div class="form-group filter-btn">
                        <button @click="filterStatistics()" class="btn btn-success">Filter</button>
                    </div>

                </div>
            </div>
            <div class="statistic-section-main">
                <div class="statistic-section-content">
                    <p>{{ data.played_matches }}/{{data.total_event_matches}}</p>
                    <p>Played / Total Matches</p>
                </div>
                <div class="statistic-section-content section-content-second">
                    <p>{{avg_wing()}}%</p>
                    <p>Avg Win</p>
                </div>

            </div>
            <div class="statistic-section-main">
                <div class="statistic-section-content">
                    <p>{{ data.earn_points !=null ? data.earn_points : 0 }}</p>
                    <p>Total Earned Points</p>
                </div>
                <div class="statistic-section-content section-content-second section-content-minus">
                    <p>{{ data.deduct_points !=null ? data.deduct_points : 0 }}</p>
                    <p>Total Points Deducted</p>
                </div>

            </div>
            <div class="statistic-section-main">
                <div class="statistic-section-content">
                    <p>{{data.win_matches}}/{{ data.double_prize_bet }}</p>
                    <p>Win / Double Prize Bet</p>
                </div>
                <div class="statistic-section-content section-content-second">
                    <p>{{double_prize_win_per()}}%</p>
                    <p>Double Prize Bet Win</p>
                </div>

            </div>

            <div class="statistic-section-content-top-team">
                <p>Top Teams You Bet For</p>
                <div class="tatistic-section-content-top-team-logos">
                    <span v-for="(item,index) in data.top_bet_teams" :key="index">
                        <img class="fluid" :src="$imagesBaseUrl+item.logo" alt="icon" width="41" height="41">
                    </span>
                </div>
            </div>
        </div>
    </b-container>
</div>
</template>

<script>
import Header from '../components/Header.vue'

export default {
    name: 'Statistic',
    props: {},
    components: {
        Header,
    },
    data: function () {
        return {
            data: [],
            errors:'',
            showDatePicker: false,
            // selectedDate: [
            //     new Date(new Date().getTime() - 28 * 24 * 60 * 60 * 1000),
            //     new Date()

            // ]
            sdate: new Date(new Date().getTime() - 28 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
            edate: new Date().toISOString().slice(0, 10)
        }
    },
    methods: {
        dateDifference() {

            const startDate = new Date(this.sdate);
            const endDate = new Date(this.edate);

            const diffInMs = new Date(endDate) - new Date(startDate)
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

            const numberOfDays = diffInDays.toFixed(0);

            // converting days to month and year if it is

            // var years = Math.floor(numberOfDays / 365);
            // var months = Math.floor(numberOfDays % 365 / 30);
            // var days = Math.floor(numberOfDays % 365 % 30);

            // return [years, months, days].join(':');

            if (numberOfDays == 0)
                return "Today record";
            else
                return "Last " + numberOfDays + " days";
        },
        filterStatistics() {

            if(this.sdate=="" || this.edate=="")
            this.errors="Please enter the start date and end date both";
            else if(this.edate>new Date().toISOString().slice(0, 10))
            this.errors="End Date can't be after current date";
            else if(this.sdate>this.edate)
            this.errors="Start Date must be equal or before End Date";
            else
            this.$http.get(this.$baseUrl + 'api/user/statistic-filtered/' + this.$cookie.get('user_id'), {
                params: {
                    sdate: this.sdate,
                    edate: this.edate
                }
            }).then((response) => {
                this.data = response.data;
                this.showDatePicker = false;
            })

        },
        get_user_statistic() {
            this.$http.get(this.$baseUrl + 'api/user/statistic/' + this.$cookie.get('user_id')).then((response) => {
                this.data = response.data;
            })
        },
        avg_wing() {
            var avg_wins = null;
            if (this.data.win_matches != 0 || this.data.played_matches != 0) {
                avg_wins = ((this.data.win_matches / this.data.played_matches) * 100)
            } else {
                avg_wins = 0;
            }
            return avg_wins.toFixed(0);
        },
        double_prize_win_per() {
            var double_win_per = '';
            if (this.data.double_prize_bet != 0 && this.data.double_prize_bet != "" && this.data.double_prize_win_per != "") {
                double_win_per = ((this.data.double_prize_win_per / this.data.double_prize_bet) * 100);
            } else {
                double_win_per = 0;
            }
            return double_win_per.toFixed(0);
        }

    },
    created() {
        // this.get_user_statistic();
        this.filterStatistics();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.profile-pages .list-group {
    padding: 20px 8px 8px;
}

.profile-pages .list-group-item .arrow-right img {
    width: 20px;
    height: 20px;
}

.profile-pages .list-group-item {
    position: relative;
}

.profile-pages .list-group-item .arrow-right {
    position: absolute;
    right: 10px;
}

.profile-pages .list-group .icon {
    margin-right: 22px;
}

a.list-group-item.list-group-item-action {
    padding: 16px;
    border: 0px;
    background: rgba(46, 36, 69, 0.24);
    background-color: rgb(250 250 251);
    border-radius: 8px;
    margin-bottom: 16px;
    color: rgba(27, 22, 38, 0.6);
    height: 56px
}
</style>

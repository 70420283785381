<template>
    <div class="home-page">
        <Header></Header>
        <b-container fluid="sm">
            <PostsColumns ></PostsColumns>
        </b-container>
    </div>
</template>

<script>
    import Header from '../components/Header.vue'
    import PostsColumns from '../components/PostsColumns.vue'

    export default {
        name: 'HomePage',
        components: {
            Header,
            PostsColumns
        },
        mounted(){
            if(this.$cookie.get("user_id"))
            this.$store.dispatch('getDiamonds',this.$cookie.get("user_id"),)
        }
       
    }

    
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


<template>
    <div class="page-title">
        <h4 class="home-tournaments-title">{{heading}}</h4>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle',
        props: {
            heading: String
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .page-title h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        margin-top: 26px;
    }
</style>

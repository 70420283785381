import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from '../views/HomePage.vue'
import ProfilePage from '../views/ProfilePage.vue'
import InviteFriendsPage from '../views/InviteFriendsPage.vue'
import TournamentsPage from '../views/TournamentsPage.vue'
import BetPage from '../views/BetPage.vue'
import ChangeBet from '../views/ChangeBet.vue'
import BetResult from '../views/BetResult.vue'
import EditProfile from '../views/EditProfile.vue'
import MyTournaments from '../views/MyTournaments.vue'
import Rewards from '../views/Rewards.vue'
import Statistic from '../views/Statistic.vue'
import LoginPage from '../views/LoginPage.vue'
import RegisterPage from '../views/RegisterPage.vue'
import CallBack from '../views/CallBack.vue'
import Wallet from '../views/Wallet.vue'
import HowItWorks from '../views/HowItWorks.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfilePage
    },
    {
        path: '/invite-friends',
        name: 'invite-friends',
        component: InviteFriendsPage
    },
    {
        path: '/tournaments/:id',
        name: 'tournaments',
        component: TournamentsPage
    },
    {
        path: '/bet/:id',
        name: 'bet',
        component: BetPage
    },
    {
        path: '/change-bet',
        name: 'change-bet',
        component: ChangeBet
    },
    {
        path: '/bet-result',
        name: 'BetResult',
        component: BetResult
    },
    {
        path: '/edit-profile',
        name: 'EditProfile',
        component: EditProfile
    },
    {
        path: '/statistic',
        name: 'Statistic',
        component: Statistic
    },
    {
        path: '/my-tournaments',
        name: 'MyTournaments',
        component: MyTournaments
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/register',
        name: 'RegisterPage',
        component: RegisterPage
    },
    {
        path: '/rewards',
        name: 'Rewards',
        component: Rewards
    },
    {
        path: '/how-it-works',
        name: 'HowItWorks',
        component: HowItWorks
    },
    {
        path: '/wallet',
        name: 'Wallet',
        component: Wallet
    },
  
    {
        path: '/auth/:provider/callback',
        name:'CallBack',
        component: CallBack
      },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    let access_token = Vue.cookie.get('sanctum_token');
    const fun = () => {
        if (access_token == null && to.path != '/' && to.name != 'tournaments' && to.path != '/register' && to.path != '/login' && to.path!='/auth/facebook/callback' && to.path!='/auth/google/callback') {
            next("/login")
        } else {
            next()
        }
    }
    fun()
});
export default router
<template>
    <div class="home-page">
        <Header headerType="profile"></Header>
        <b-container fluid="sm">
            <div class="profile-pages">
                <b-list-group>
                    <b-list-group-item href="#">
                    <router-link :to="'/statistic'">
                        <span class="icon"><img src="../assets/images/svg/chart.svg" alt="icon"></span>
                        Statistics
                        <span class="arrow-right"><img src="../assets/images/svg/arrow-right.svg" alt="icon"></span>
                    </router-link>
                    </b-list-group-item>
                    <b-list-group-item href="#">
                    <router-link :to="'/my-tournaments'">
                        <span class="icon"><img src="../assets/images/svg/ranking.svg" alt="icon"></span>
                        My Tournaments
                         <span class="arrow-right"><img src="../assets/images/svg/arrow-right.svg" alt="icon"></span>
                      </router-link>
                    </b-list-group-item>
                    <b-list-group-item href="#">
                      <router-link :to="'/edit-profile'">
                        <span class="icon"><img src="../assets/images/svg/setting.svg" alt="icon"></span>
                          Profile Settings
                        <span class="arrow-right"><img src="../assets/images/svg/arrow-right.svg" alt="icon"></span>
                      </router-link>
                    </b-list-group-item>
                    <b-list-group-item href="#" @click="logout">
                        <span class="icon"><img src="../assets/images/svg/logout.svg" alt="icon"></span>
                        Logout</b-list-group-item>
                </b-list-group>
            </div>
        </b-container>
    </div>
</template>

<script>
    import Header from '../components/Header.vue'

    export default {
        name: 'ProfilePage',
        props: {},
        components: {
            Header,
        },
        data: function () {
            return {
               
            }
        },
        methods:{
            logout()
            {
                this.$cookie.delete('sanctum_token');
                this.$cookie.delete('user_id');
                this.$cookie.delete('user_data');
                this.$cookie.delete('diamonds');
                this.$cookie.delete('coins');
                this.$router.go("/")
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
   .profile-pages .list-group {
        padding: 20px 8px 8px;
    }
    .profile-pages .list-group-item .arrow-right img{
        width: 20px;
        height: 20px;
    }

    .profile-pages .list-group-item{
        position:relative;
    }
    .profile-pages .list-group-item .arrow-right{
        position:absolute;
        right :10px;
    }
    .profile-pages .list-group .icon{
        margin-right:22px;
    }
    a.list-group-item.list-group-item-action {
    padding: 16px;
    border: 0px;
    background: rgba(46, 36, 69, 0.24);
    background-color: rgb(250 250 251);
    border-radius: 8px;
    margin-bottom: 16px;
    color: rgba(27, 22, 38, 0.6);
    height:56px
    }
    .profile-pages .list-group a {
    text-decoration: none;
    color: #7471a0;
}

</style>

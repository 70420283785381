<template>
<div class="login-page">
    <div class="container h-100">
        
        <p class="main-title"><img src="../../src/assets/images/88sportlogo.png" alt=""></p>
        <div class="row h-100 align-items-center">
            <div class="col-md-12">
                <div class="card-body login-card-body">
                    <a class="back-btn" @click="$router.go(-1)"><img src="../../src/assets/images/svg/left-arrow.svg" alt=""></a>
                    <div class="login-header"><h class="login-heading">Login</h></div>
                    
                    <div class="form-section">
                        <div class="errors" v-if="errors.length>0">
                            <div v-for="(error,index) in errors" :key="index">
                                <p class=" error">{{error}}</p>
                            </div>
                        </div>
                        <form class="login-form">
                            <div class="input-group mb-3">
                                <input v-model="form.email" type="text" class="form-control" placeholder="Email">
                                <div class="input-group-append">
                                    <span class="input-group-text"><img class="login-email-icon" src="../../src/assets/images/svg/email.svg" alt=""></span>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <input v-model="form.password" :type="password_type" class="form-control" placeholder="Password">
                                <div class="input-group-append">
                                    <span class="input-group-text"><img class="login-password-icon" @click="passwordToggle()" src="../../src/assets/images/svg/eye.svg" alt=""></span>
                                </div>
                            </div>
                            <span class="forgot-password-link">
                                <router-link to="/forgot-password">Forgot Password?</router-link>
                            </span>
                            <div class="form-group">
                                <button type="button" @click="user_authenticate(form)" class="btn w-100 btn-login">Login</button>
                            </div>
                        </form>
                        <span class="defferent-login">
                            <p class="or">or</p>
                        </span>
                        
                        <div @click="AuthProvider('google')" class="google-login">
                        <img src="../../src/assets/images/google-icon.webp" class="google-login-img w-100"/> Continue with Google
                        </div>
                        <div class="registration-section">
                            Don't have account? <router-link class="register-page-link" to="/register">Register</router-link>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
export default {
    name: "LoginPage",

    mounted() {

        if (this.$route.query.uid) {
            this.refer_id = this.$route.query.uid;
            document.cookie = "refer_id=" + this.refer_id;
        } else
            this.refer_id = this.$cookie.get('refer_id');

        if (this.$cookie.get('registeredUser'))
            this.refer_id = '';
    },

    data() {
        return {
            errors:[],
            password_type:'password',
            refer_id: '',
            form: {
                email: "",
                password: ''
            },
            processing: false
        }
    },
    methods: {

        passwordToggle(){
            this.password_type = this.password_type=='password'?'text':'password';
        },

        AuthProvider(provider) {

            var self = this

            this.$auth.authenticate(provider).then(response => {

                self.SocialLogin(provider, response)

            })
        },

        SocialLogin(provider, response) {

            var url = this.$baseUrl + 'api/sociallogin/' + provider;

            if (this.refer_id)
                response = {
                    ...response,
                    refer_id: this.refer_id
                };

            this.$http.post(url, response).then(function (data) {

                if (data.data.status == true) {
                    const user_data = data.data.user;
                    document.cookie = "registeredUser=yes";
                    document.cookie = "sanctum_token=" + data.data['token'];
                    document.cookie = "user_id=" + user_data['user_id'];
                    document.cookie = "user_data=" + JSON.stringify(user_data)
                    this.$userData = user_data;
                    this.$userId = user_data['user_id'];
                    this.$router.push("/")
                } else {
                    alert(data.data.message);
                }

            }.bind(this));
        },

        user_authenticate(form) {
            this.$http.post(this.$baseUrl + 'api/login', form).then(function (data) {
                if(data.data.status == 'not')
                {
                    this.errors.push(data.data.message);
                }
                else if (data.data.status == true) {
                    document.cookie = "registeredUser=yes";
                    const user_data = data.data.user;
                    document.cookie = "sanctum_token=" + data.data['token'];
                    document.cookie = "user_id=" + user_data['user_id'];
                    document.cookie = "user_data=" + JSON.stringify(user_data)
                    this.$userId = user_data['user_id'];
                    this.$userData = user_data;

                    this.$router.push("/")
                } else
                {
                    this.errors = Object.values(data.data.errors);
                    this.errors = this.errors.flat();
                }
            }.bind(this)).finally(() => {
                this.processing = false
            })
        },

    }
}
</script>

<template>
<div class="auth-component"></div>
</template>

<script>
export default {
    name: 'CallBack',
    mounted() {
    }

}
</script>

<template>
<div class="invite-friends-page">
    <div class="title-head">
        <div class="arrow-left">
             <router-link :to="'/'"><span class="icon"><img src="../assets/images/svg/arrow-left-black.svg" alt="icon"></span></router-link>
        </div> 
        <div class="invite-friends-title">Invite Friends</div>
    </div>
    <div class="diamond">
        <span class="icon"><img src="../assets/images/diamond.png" alt="icon"></span>
        <p class="get-diamond">Get Diamond</p>
        <p class="text">Invite a friend and get a diamond, and you can change your bet after match start until 30 min</p>
    </div>

    <div class="social-media">
        <ul>

            <!-- <li><a href="#"><img src="../assets/images/telegram.png" alt="twitter"></a></li> -->
            <li><a :href="'https://www.facebook.com/sharer.php?u=https://88sport.club/register?uid='+user_id"  target='_blank'><img src="../assets/images/svg/facebook.svg" alt="facebook"></a></li>
            <li><a :href="'https://api.whatsapp.com/send?text=%2AClick%20and%20Join%20the%20%22following%20WhatsApp%22%20link%2A%0A%0Ahttps%3A%2F%2F88sport.club%2Fregister%3Fuid%3D'+user_id" target='_blank' data-action="share/whatsapp/share"><img src="../assets/images/whatsapp.png" class="invite-via-whatsapp" alt="whatsapp"></a></li>
        </ul>
     
    </div>
</div>
</template>
<script>
    export default {
        name: 'InviteFriendsPage',
        data: function () {
            return {
                user_id:this.$cookie.get('user_id'),
            }
        },
        methods:{
           
        },
    }
</script>
<style scoped>

.invite-friends-page .title-head{
   text-align:center;
   position:relative;
   margin-top:30px;
}
.invite-friends-title{
    font-weight:600;

}
.title-head .arrow-left {
    position: absolute;
    left: 9px;
}
.invite-friends-page .diamond{
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top:100px;
 }
.invite-friends-page .diamond img{
    height:107.15px;
    width:107.15px;
 }
 .diamond .get-diamond{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
 }
.diamond .text{
    font-weight:400;
    text-align:center;
    padding-right:55.86px;
    padding-left:55.86px;
    font-size:12px;
    line-height: 18px;
}
.social-media{text-align:center; margin-top:33px}

.social-media ul {
    display: inline-flex;
    padding:0px;
    list-style: none;
}
.social-media ul li{
 margin-left:10px;
}

img.invite-via-whatsapp {
    margin-top: -2px;
    min-height: 46px;
}
</style>
<template>
<div class="home-page">
    <Header headerType="profile"></Header>
    <b-container fluid="sm">
        <form @submit.prevent="updateUser()" ref="regsiterForm">
            <div class="edit-prfile-form">
                <label for="image">Profile Image(Optional)</label>
                <div class="form-group">
                    <div class="edit-profile-input">
                        <img src="../assets/images/svg/user-form.svg" alt="icon">
                        <input accept="image/png,image/jpeg" id="image" ref="image" type="file" v-on:change="onChangeFileUpload($event)" />
                    </div>
                </div>
            </div>
            <div class="edit-prfile-form">
                <label for="exampleInputname">Nickname</label>
                <div class="form-group">
                    <div class="edit-profile-input">
                        <img src="../assets/images/svg/user-form.svg" alt="icon">
                        <input type="text" class="form-control" id="exampleInputname" aria-describedby="emailHelp" placeholder="John Doe" v-model="user.name">
                    </div>
                </div>
            </div>
            <div class="edit-prfile-form">
                <label for="exampleInputEmail1">Email Address</label>
                <div class="form-group">
                    <div class="edit-profile-input">
                        <img src="../assets/images/svg/sms-form.svg" alt="icon">
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="johndoe@gmail.com" v-model="user.email">
                    </div>
                </div>
            </div>
            <div class="edit-profile-button">
                <button type="submit" class="btn btn-lg btn-block">Save Updates</button>
            </div>

        </form>
    </b-container>
</div>
</template>

<script>
import Header from '../components/Header.vue'
export default {
    name: 'EditProfile',
    props: {},
    components: {
        Header,
    },
    data: function () {
        return {
            user: [],
            url: '',
            image: ''
        }
    },
    mounted() {

        var userData = JSON.parse(this.$cookie.get('user_data'));

        this.user = {
            userId: userData.user_id,
            name: userData.name,
            email: userData.email,
        }

    },
    methods: {
        onChangeFileUpload(event) {
            this.image = this.$refs.image.files[0];
            this.url = URL.createObjectURL(this.image);
        },
        updateUser() {
            let formData = new FormData(this.$refs.regsiterForm);

            formData.append('image', this.image);
            formData.append('user_id', this.user.userId);
            formData.append('name', this.user.name);
            formData.append('email', this.user.email);
            formData.append('image', this.image);

            this.$http.post(this.$baseUrl + 'api/user/update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                this.update_userdata_cookie(response.data.image);
            })
        },
        update_userdata_cookie(image) {
            let user_data = {
                user_id: this.user.userId,
                name: this.user.name,
                email: this.user.email,
                image: image
            };
            document.cookie = "user_data=" + JSON.stringify(user_data)
            if(this.image!='')
            location.reload();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.profile-pages .list-group {
    padding: 20px 8px 8px;
}

.profile-pages .list-group-item .arrow-right img {
    width: 20px;
    height: 20px;
}

.profile-pages .list-group-item {
    position: relative;
}

.profile-pages .list-group-item .arrow-right {
    position: absolute;
    right: 10px;
}

.profile-pages .list-group .icon {
    margin-right: 22px;
}

a.list-group-item.list-group-item-action {
    padding: 16px;
    border: 0px;
    background: rgba(46, 36, 69, 0.24);
    background-color: rgb(250 250 251);
    border-radius: 8px;
    margin-bottom: 16px;
    color: rgba(27, 22, 38, 0.6);
    height: 56px
}
</style>

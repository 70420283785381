import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    diamonds: 0,
    coins: 0,
    tab: 'history',
    activeShow: 'active show'
  },
  mutations: {
    setDiamonds(state, data) {
      state.diamonds = data.diamonds,
        state.coins = data.coins
    },
    setTab(state, tab) {
      state.tab=tab;
    }

  },
  actions: {
    getDiamonds: ({ commit }, user_id) => {
      Axios.get('https://admin.88sport.club/' + 'api/diamonds/' + user_id).then((response) => {

        commit("setDiamonds", response.data)
      })
    }
  },
  modules: {
  }
})

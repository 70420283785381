<template>
  <div class="bet-page">
    <div class="header">
      <div class="back-btn">
        <a role="button" @click="$router.back()"
          ><img src="../assets/images/svg/arrow-left.svg" alt="icon"
        /></a>
      </div>
      <div class="title">
        <p>Bet on your Team</p>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="team-bet-section">
            <div class="team-one-bet-section">
              <img class="fluid" :src="$imagesBaseUrl + teamOneLogo" />
              <p class="team-name">{{ teamOneName }}</p>
            </div>
            <div class="team-vs-bt-logo">
              <img class="fluid" src="../assets/images/svg/bet-page-vs.svg" />
            </div>
            <div class="team-two-bet-section">
              <img class="fluid" :src="$imagesBaseUrl + teamTwoLogo" />
              <p class="team-name">{{ teamTwoName }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="team-prizes">
        <div class="team-one-prize">
          <div class="team-one-prize-box">
            <span><img class="fluid" src="../assets/images/svg/coin.svg" /></span>
            <span :class="[team1 ? 'text-yellow' : '']">{{ oddOne }} Prize</span>
          </div>
          <div class="prize-radio">
            <input
              @click="checkbox('teamone')"
              name="prize"
              type="radio"
              :checked="
                teamOneId && betTeamId && teamOneId.toString() === betTeamId.toString()
              "
            />
          </div>
        </div>
        <div class="team-one-prize">
          <div class="team-one-prize-box">
            <span><img class="fluid" src="../assets/images/svg/coin.svg" /></span>
            <span :class="[team2 ? 'text-yellow' : '']">{{ oddTwo }} Prize</span>
          </div>

          <div class="prize-radio">
            <input
              @click="checkbox('teamtwo')"
              name="prize"
              type="radio"
              :checked="
                teamTwoId && betTeamId && teamTwoId.toString() === betTeamId.toString()
              "
            />
          </div>
        </div>
      </div>

      <div class="team-bet-section_date">
        <p>{{ moment(data.date).format("ddd, Do MMM") }} - {{ data.time }}</p>
        <div class="team-bet-section_details">
          <div class="team-bet-section_details-title">
            <span>Double Your Prize</span>
            <span>
              <label class="toggle-control">
                <input
                  type="checkbox"
                  @click="point_state($event)"
                  :checked="doublePrize"
                />
                <span class="control"></span>
              </label>
            </span>
          </div>
          <div class="team-bet-section_details-desc">
            <p>
              If you win, you will get double of your prize, but if you lose, same amount
              of coin will be deducted.
            </p>
          </div>
        </div>
      </div>
      <div v-if="bet_id === null" class="entry-save">
        <div class="bet-offer-title">
          <span>You can place a bet for free.</span>
        </div>
        <div class="bet-offer-conform">
          <div>
            <b-button v-b-modal.modalPopover>Confirm Your Bet</b-button>
            <b-modal
              ref="modalPopover"
              id="modalPopover"
              hide-footer
              ok-title="Confirm"
              cancel-title="Cancel"
            >
              <div class="modal-content-box">
                <p>
                  <img
                    class="fluid confirm-bet-image"
                    :src="$imagesBaseUrl + [team1 ? teamOneLogo : teamTwoLogo]"
                    alt="icon"
                  />
                </p>
                <p>Congratulations</p>
                <p>
                  You have successfully bet for<b>
                    {{ team1 ? teamOneName : teamTwoName }} </b
                  >in this match
                </p>
                <div class="modal-bottom">
                  <b-button class="mt-3" block @click="cancelBet">Cancel</b-button>
                  <b-button class="mt-3" block @click="confirmBet">Confirm</b-button>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
      <div v-else class="entry-update">
        <div class="bet-offer-title">
          <span
            >You have placed your bet, you can change your bet by using a diamond.</span
          >
        </div>
        <div class="bet-offer-conform">
          <div>
            <b-button :disabled="checkBetChanged" v-b-modal.modalPopover
              >Change Bet</b-button
            >
            <b-modal
              ref="modalPopover"
              id="modalPopover"
              hide-footer
              ok-title="Confirm"
              cancel-title="Cancel"
            >
              <div class="modal-content-box">
                <p>
                  <img
                    class="fluid confirm-bet-image"
                    src="../assets/images/coin1.png"
                    alt="icon"
                  />
                </p>
                <div v-if="errors" class="change-bet-error">{{ errors }}</div>
                <p>Change Bet Confirmation</p>
                <p>If you click <b>yes,</b> you will lose</p>
                <br />
                <p class="losing-daimonds">{{ diamonds_per_change_bet }} diamond</p>
                <div class="modal-bottom">
                  <b-button class="mt-3" block @click="cancelBet">Cancel</b-button>
                  <b-button class="mt-3" block @click="confirmBet">Confirm</b-button>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "BetPage",
  data: function () {
    return {
      data: "",
      errors: "",
      diamonds_per_change_bet: null,
      bet_selected_team_changed: false,
      bet_double_prize_changed: false,
      oddOne: null,
      oddTwo: null,
      eventId: null,
      teamOneId: null,
      teamTwoId: null,
      tournamentId: null,
      teamOneLogo: "",
      teamTwoLogo: "",
      teamOneName: "",
      teamTwoName: "",
      team1: true,
      team2: false,
      bet_id: null,
      betTeamId: null,
      Prize: null,
      doublePrize: false,
      user_id: this.$cookie.get("user_id"),
      moment: moment,
    };
  },
  methods: {
    cancelBet() {
      this.$refs["modalPopover"].hide();
    },
    confirmBet() {
      if (this.bet_id > 0 && this.$store.state.diamonds < this.diamonds_per_change_bet) {
        this.errors = "You dont have the required diamonds to change bet";
        return;
      }

      this.$http
        .post(this.$baseUrl + "api/bet/save", {
          tournament_id: this.tournamentId,
          bet_id: this.bet_id, //primary id of bets table
          event_id: this.eventId,
          bet_team_id: this.betTeamId,
          prize: this.Prize,
          doublePrize: this.doublePrize,
          user_id: this.user_id,
          diamonds_per_change_bet: this.diamonds_per_change_bet,
        })
        .then((response) => {
          location.reload();
        });
      this.$refs["modalPopover"].hide();
      this.$router.push("/bet/" + bet_id);
    },
    getBetTeam() {
      this.$http
        .get(this.$baseUrl + "api/bet/get/bet-team/" + this.$route.params.id, {
          params: {
            user_id: this.user_id,
          },
        })
        .then((response) => {

          this.data = response.data.data[0];

          // diamonds per change bet
          this.diamonds_per_change_bet = response.data.diamonds_per_change_bet;

          var odd1 = (this.data.odd_one * 1.5 - 1) * 100;
          var odd2 = (this.data.odd_two * 1.5 - 1) * 100;

          if (odd1 <= 0) this.oddOne = 10;
          else if (odd1 >= 0 && odd1 <= 10) this.odd1 = odd1 + 10;
          else this.oddOne = odd1.toFixed(0);

          if (odd2 <= 0) this.oddTwo = 10;
          else if (odd2 >= 0 && odd2 <= 10) this.oddTwo = odd2 + 10;
          else this.oddTwo = odd2.toFixed(0);

          this.eventId = this.data.id;
          this.tournamentId = this.data.tournament_id;
          this.teamOneId = this.data.team_one;
          this.teamTwoId = this.data.team_two;

          this.teamOneLogo = this.data.team_one_logo;
          this.teamTwoLogo = this.data.team_two_logo;
          this.teamOneName = this.data.team_one_name;
          this.teamTwoName = this.data.team_two_name;

          this.bet_id = this.data.bet_id; /*bet table primary id for update data*/
          this.betTeamId = this.data.team_one;
          this.Prize = this.data.prize_weight;
          this.doublePrize = false;

          if (this.data.bet_team_id != null) {
            this.betTeamId = this.data.bet_team_id;
            if (this.data.bet_team_id == eval(this.teamOneId)) {
              this.team1 = true;
              this.team2 = false;
              this.betTeamId = this.teamOneId;
            } else {
              this.team1 = false;
              this.team2 = true;
              this.betTeamId = this.teamTwoId;
            }
            if (this.data.double_prize == 0) {
              this.doublePrize = false;
            } else {
              this.doublePrize = true;
            }
          } else {
            this.betTeamId = this.teamOneId;
          }
        });
    },
    checkbox(data) {
      this.bet_selected_team_changed = !this.bet_selected_team_changed;
      /** team checkbox */
      if (data == "teamtwo") {
        this.team1 = false;
        this.team2 = true;
        this.betTeamId = this.teamTwoId;
      } else {
        this.team1 = true;
        this.team2 = false;
        this.betTeamId = this.teamOneId;
      }
    },
    point_state(e) {
      this.bet_double_prize_changed = !this.bet_double_prize_changed;
      this.doublePrize = e.target.checked;
    },
  },
  computed: {
    checkBetChanged() {
      if (
        this.bet_double_prize_changed != false ||
        this.bet_selected_team_changed != false
      )
        return false;
      else return true;
    },
  },
  created() {
    this.getBetTeam();
  },
  watch: {},
};
</script>

<style scoped></style>

<template>
<div class="app-header" :class="{'profile-header': headerType == 'profile'}">
    <b-container fluid="sm">
        <div class="header-bar">
            <div class="logo">
                <div v-b-toggle.sidebar-1>
                    <img @click="ok()" src="../assets/images/svg/menu.svg" alt="hamburgermenu">
                </div>
                <h5><span class="first">
                    <img src="../assets/images/88sportlogo.png" alt="88sport-logo">
                    </span>
                </h5>

            </div>
            <div class="avatar">
                <router-link v-if="page!='wallet'" :to="'/wallet'" style="text-decoration: none;">
                <div class="coins">
                    <span role="button" class="coin" @click="$store.commit('setTab','coins')">
                    <img src="../assets/images/coin.png" alt="coin" />
                    <span class="coin-count">{{ coins }}</span>
                    </span>
                    <span role="button" class="diamond" @click="$store.commit('setTab','diamond')">
                    <img src="../assets/images/coin1.png" alt="diamond-image">
                    <span class="coin-count">{{ diamonds }}</span>
                    </span>
                </div>
                </router-link>
                <div v-else class="coins">
                    <span role="button" class="coin" @click="$store.commit('setTab','coins')">
                    <img src="../assets/images/coin.png" alt="coin" />
                    <span class="coin-count">{{ coins }}</span>
                    </span>
                    <span role="button" class="diamond" @click="$store.commit('setTab','diamond')">
                    <img src="../assets/images/coin1.png" alt="diamond-image">
                    <span class="coin-count">{{ diamonds }}</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="profile-avatar" v-if="headerType == 'profile'">
            <img class="user-avatar" v-if="(userData('image')!=null)" :src="userData('image')" alt="profile">
            <img class="user-avatar" v-else src="../assets/images/avatar.png" alt="profile">
            <div class="d-flex flex-column align-items-center mt-3">
                <h5 class="mt-0 text-white">
                    {{ userData('name') }}
                </h5>
                <p class="mb-0 text-white">
                    {{ userData('email') }}
                </p>
            </div>
        </div>
        <div>
            <b-sidebar id="sidebar-1" shadow>
                <div class="px-3 py-2">
                    <div class="sidebar-header">
                        <b-media class="d-flex align-items-center">
                            <template #aside>
                                <router-link :to="'/profile'" :class="userData('image')!=null?'user-avatar':''">
                                    <img v-if="(userData('image')!=null)" :src="userData('image')" alt="avatar">
                                    <img v-else src="../assets/images/avatar.png" alt="avatar">
                                </router-link>
                            </template>
                            <router-link :to="'/profile'">
                                <h5 class="mt-0">
                                    <!-- admin -->
                                    {{ userData('name') }}
                                    <!-- <span class="pl-2">
                                        <img src="../assets/images/small-award.png" alt="avatar">

                                    </span> -->
                                </h5>
                                <p class="mb-0">
                                    {{ userData('email') }}
                                    <!-- admin@gmail.com -->
                                </p>
                            </router-link>
                        </b-media>
                    </div>
                    <hr>
                    <div class="sidebar-menu text-left">
                        <b-nav class="d-flex flex-column">
                            <b-nav-item active href="#">
                                <router-link :to="'/'">
                                    <span class="icon">
                                        <img src="../assets/images/svg/home.svg" alt="avatar">
                                    </span>
                                    Home
                                </router-link>
                            </b-nav-item>
                            <b-nav-item>
                                <router-link to="/how-it-Works">
                                <span class="icon">
                                    <img src="../assets/images/svg/help-circle.svg" alt="avatar">
                                </span>
                                How it Works
                            </router-link>
                            </b-nav-item>
                            <b-nav-item>
                                <span class="icon">
                                    <img src="../assets/images/svg/cup.svg" alt="Reward">
                                </span>

                                <router-link :to="'/rewards/'">
                                    Rewards
                                </router-link>
                            </b-nav-item>

                            <b-nav-item>
                                <span class="icon">
                                    <img src="../assets/images/svg/wallet.svg" alt="Reward">
                                </span>

                                <router-link :to="'/wallet/'">
                                    Wallet
                                </router-link>
                            </b-nav-item>
                            <b-nav-item href='http://bit.ly/3i8YBcb' target="_blank">
                                    <span class="icon">
                                        <img src="../assets/images/svg/handshake.svg" alt="Reward">
                                    </span>Sponsored By OX88
                            </b-nav-item>
                            <b-nav-item href='#'>
                                <router-link :to="'/invite-friends'">
                                    <span class="icon">
                                        <img src="../assets/images/svg/diamond-invite.svg" alt="icon">
                                    </span>Invite & Get Diamonds</router-link>
                            </b-nav-item>
                            <b-nav-item href="https://www.casinopub.club/contact/" target="_blank">
                                <!-- <router-link :to="'/contact-us'"> -->
                                <span class="icon">
                                    <img src="../assets/images/svg/call.svg" alt="icon">
                                </span>
                                Contact Us
                                <!-- </router-link> -->
                            </b-nav-item>
                            <b-nav-item @click="logout">
                                <span class="icon">
                                    <img src="../assets/images/svg/logout.svg" alt="icon">
                                </span>
                                Logout
                            </b-nav-item>
                        </b-nav>
                    </div>
                    <div class="sidebar-footer">
                        <div class="social-icons d-flex justify-content-end align-items-center">
                            <a :href="'https://www.facebook.com/sharer.php?u=https://88sport.club/register?uid='+this.$cookie.get('user_id')" target='_blank'><img class="facebook-img" src="../assets/images/svg/facebook.svg" alt="facebook"></a>
                            <a :href="'https://api.whatsapp.com/send?text=%2AClick%20and%20Join%20the%20%22following%20WhatsApp%22%20link%2A%0A%0Ahttps%3A%2F%2F88sport.club%2Fregister%3Fuid%3D'+this.$cookie.get('user_id')" target='_blank' data-action="share/whatsapp/share"><img class="whatsapp-img" src="../assets/images/svg/whatsapp-icon-new.svg" alt="whatsapp"></a>
                        </div>
                    </div>
                </div>
            </b-sidebar>
        </div>
    </b-container>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
export default {
    name: 'Header',
    props: {
        headerType: String,
        page: String
    },
    data: function () {
        return {
            user_data: this.$cookie.get('user_data'),
        }
    },
    mounted() {
        if(this.$cookie.get('user_id'))
        this.$store.dispatch('getDiamonds', this.$cookie.get('user_id'))
    },
    methods: {
        ok(){
           if(!this.$cookie.get('user_id'))
           this.$router.push('/login');

        },
        userData(key) {
            const user_data = JSON.parse(this.$cookie.get('user_data'));
            if(user_data)
            return user_data[key];
            else user_data;
        },
        logout() {
            this.$cookie.delete('sanctum_token');
            this.$cookie.delete('user_id');
            this.$cookie.delete('refer_id');
            this.$cookie.delete('user_name');
            this.$cookie.delete('user_image');
            this.$cookie.delete('user_data');
            this.$cookie.delete('diamonds');
            this.$cookie.delete('coins');
            window.location.href = this.$appBaseUrl
        },

    },
    computed: {
        ...mapState({
            diamonds: (state) => state.diamonds,
            coins: (state) => state.coins
        })

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.app-header {
    width: 100%;
    min-height: 87px;
    background: #2E2445;
}

.app-header.profile-header {
    min-height: 322px;
}

.app-header.profile-header .profile-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    flex-direction: column;
}

.header-bar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
}

.header-bar .logo,
.header-bar .avatar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header-bar .logo h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
}

.avatar .profile-avatar {
    max-width: 33px
}

.avatar .coins img {
    max-width: 19px
}

.coins {
    position: relative;
}

.coin-count {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #00C3FF;
    padding-left: 10px;
}

.logo .first,
.logo .last {
    font-weight: 700;
    font-size: 18px;
    font-style: italic;
    line-height: 27px;
}

.logo .last {
    color: #FFB545;
}

.logo .first {
    color: white;
}

.logo img {
    height: 24px;
}

.logo h5,
.sidebar-header h5 {
    margin: 0;
}

.sidebar-header a {
    text-decoration: none;
    color: #655E6B;
}

.sidebar-header a h5 {
    font-weight: 600;
    color: #3A3142;
    text-decoration: none;
}

.header-bar .btn-secondary {
    color: #fff;
    font-weight: bold;
    background-color: transparent;
    border-color: transparent;
}

.avatar-icon .b-avatar-img img {
    height: 32px !important;
    width: 32px !important;
}

.avatar-icon .badge-info {
    background-color: transparent !important;
    ;
}

/*SideBar Style*/
.sidebar-menu .nav-item a {
    display: flex;
    align-items: center;
    padding-left: 0;
}

.sidebar-menu span.icon {
    background-color: #ededed;
    border-radius: 4px;
    text-align: center;
    padding: 2px 5px;
    margin-right: 8px;
    height: 29px;
    display: flex;
    align-items: center;
}

.sidebar-menu .home {
    max-width: 33px
}

div#sidebar-1 {
    background: white !important;
}

.sidebar-menu .nav-item {
    padding: 0px 0;
}

.sidebar-menu .nav-item a {
    color: black;
}

.social-icons img {
    margin: 0 5px;
}

.profile-avatar h5 {
    font-weight: 600;
    line-height: 24px;
    font-size: 16px !important;
}

.profile-avatar p {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 18px;
}

.sidebar-menu.text-left .nav-item a {
    text-decoration: none;
}

.user-avatar {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    object-fit:cover;
    display: block;
}

.user-avatar img {
    max-width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: center;
}

span.icon img {
    min-width: 21px;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueSocialauth from 'vue-social-auth'
var VueCookie = require('vue-cookie');
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/style.css'

//date range picker
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';

Vue.component('Datepicker', VueDatepickerUi)


// Tell Vue to use the plugin
Vue.use(VueCookie);
Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.prototype.$userData = JSON.parse(Vue.cookie.get('user_data'));
Vue.prototype.$userId = Vue.cookie.get('user_id');

// Vue.prototype.$baseUrl = 'http://localhost:8000/';
// Vue.prototype.$imagesBaseUrl = "http://localhost:8000/public/storage/";


Vue.prototype.$baseUrl = 'https://admin.88sport.club/';
Vue.prototype.$imagesBaseUrl = "https://admin.88sport.club/public/storage/";

// Vue.prototype.$appBaseUrl = 'http://localhost:8080/';
Vue.prototype.$appBaseUrl = 'https://88sport.club/';



import {
    BootstrapVue,
    BootstrapVueIcons
} from 'bootstrap-vue'


Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: '544770692898-o38tuj6o3m9gjn8ht9cbn7q7r7kfcmog.apps.googleusercontent.com',
      redirectUri: 'https://88sport.club/auth/google/callback' // Your client app URL
      // redirectUri: 'http://localhost:8080/auth/google/callback' // Your client app URL

    },
    facebook: {
      clientId: '2056916057821651',
      redirectUri: 'https://88sport.club/auth/facebook/callback' // Your client app URL
      // redirectUri: 'http://localhost:8080/auth/facebook/callback' // Your client app URL
    }
  }
})


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(router)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
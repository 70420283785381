<template>
<div class="home-page tournaments">
    <Header></Header>
    <b-container fluid="sm">
        <div class="title-head reward-page d-flex justify-content-between align-items-center mb-4">
            <div class="arrow-left-link">
                <a href="/"><span class="icon"><img src="../assets/images/svg/arrow-left-black.svg" alt="icon"></span></a>
            </div>
            <div class="page-title">
                <h4>Rewards</h4>
            </div>
            <div class="user-coin">
                <div class="userCoins">{{myCoins}}</div>
            </div>
        </div>
        <div v-if="userCoins!=0" class="exchange-rewards">
            <div class="form-group">
                <label for="coint">Coin</label>
                <input type="number" v-model="coin" @keyup="check()" class="form-control" placeholder="Enter the coin to exchange">
            </div>
            <div class="form-group">
                <label for="dollor">RM</label>
                <input readonly type="text" :value="rm" class="form-control" placeholder="The exchangeable RM">
            </div>
            <div class="form-group">
                <button @click="exchangeReward()" class="btn btn-success">Exchange</button>
            </div>
        </div>
        <div v-else class="not-enough-coins">
            <h4>insufficient coins to exchange</h4>
        </div>
    </b-container>
</div>
</template>

<script>
import Header from '../components/Header.vue'
import PageTitle from '../components/PageTitle.vue'
import PostsColumns from '../components/PostsColumns.vue'

export default {
    name: 'HomePage',
    props: {},
    components: {
        Header,
        PageTitle,
        PostsColumns
    },
    data: function () {
        return {
            coin: "",
            rm: "",
            userCoins: "", 
            rm_per_coin: "", 
            rewardData: {
                rewardData: [],
            },
            userData: {
                userId: this.$userId,
            },
        }
    },
    methods: {
        check() {
            if (this.coin > this.userCoins) {
                alert("You dont have that much coins look at your available coins")
                this.rm='';
                this.coin='';
            }
            else
            this.rm=(this.coin/this.rm_per_coin);

        },
        exchangeReward() {
            if (this.coin <= this.userCoins) {
                if (this.coin.length != "" && this.rm.length != "") {
                    this.$http.post(this.$baseUrl + 'api/exchange-rewards', {
                        coin: this.coin,
                        rm: this.rm,
                        user_id: this.$userId
                    }).then((response) => {
                        alert(JSON.stringify(response.data));
                        this.$router.go(this.$router.currentRoute)
                    })
                }
                else
                {
                    alert("Pleae enter the value to successfull exchange");
                }

            } else {
                alert("You have insufficient coins");
            }

        },
        getRewards(userId) {
            this.$http.get(this.$baseUrl + 'api/rewards').then((response) => {
                this.rewardData = response.data.data;
                this.$store.dispatch('getDiamonds', userId)
            })
        },
        redeemNow(rewardId) {

            if (confirm("Are you sure you want to redeem this product")) {

                this.$http.get(this.$baseUrl + 'api/rewards/redeem/' + rewardId + '-' + this.userData.userId).then((response) => {

                    alert(response.data.data);

                    if (response.data.status == '200') {
                        this.getRewards(this.userData.userId);
                        this.$store.dispatch('getDiamonds', this.userData.userId)
                    }
                });

            } else {
                return false;
            }

        }
    },
    mounted() {
        this.getRewards(this.userData.userId);
    },
    computed: {
        myCoins(){
            var self = this;
            this.$http.get(this.$baseUrl + 'api/user-coins/' + this.$userId).then(
                (response) => {
                self.userCoins = response.data.userCoins;
                self.rm_per_coin=response.data.rm_per_coin;
            });
            return self.userCoins;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.btn {
    padding: 5px 20px;
    border-radius: 50px;
    min-width: 100px;
    margin-top: 10px;
}

.btn-orange {
    background-color: #FFB545;
    border-color: #FFB545;
    color: #000;
}

.posts.in-columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.post-box-1 {
    width: 100%;
    height: auto;
    background: #F4F6FA;
    border-radius: 16px;
    margin-top: 30px;
    position: relative;
}

.post-image-box {
    height: 36vw;
    display: block;
}

.post-box-1 .post-image-box img {
    width: 100%;
    height: 100%;
    border-radius: 16px 16px 0 0;
    object-fit: cover;
    object-position: top;
}

.post-box-1 .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 99;
    background: linear-gradient(0deg, rgba(46, 36, 69, 0.2), rgba(46, 36, 69, 0.2));
    display: none;
}

.post-box-1-footer {
    text-align: left;
    padding: 10px 15px;
    width: 100%;
    min-height: 70px;
    border-radius: 0 0 16px 16px;
    background: #F4F6FA;
    z-index: 999;
}

.post-box-1-footer h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

h5.post-title-1 a {
    color: #2E2445;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
}

.post-box-1-footer p {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #1B1626;
    opacity: 0.5;
}

.post-box-1 .coins.post-coins {
    position: absolute;
    top: 15px;
    right: 5px;
    border-radius: 50px;
    z-index: 999;
    background-color: #ffffff2b;
    padding: 4px 10px;
    min-width: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post-box-1 .coins.post-coins .coin-count {
    color: #FFB545;
    padding-left: 5px;
    min-width: 50%;
}

.reward-card-title p {
    margin-left: 5px;
}

.home-page .post-box-1 .coins.post-coins img {
    max-width: 21px !important;
}

@media (min-width: 993px) {
    .post-box-1>img {
        width: 100%;
    }

    .posts.in-columns {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .post-box-1 {
        width: 48%;
    }

    .post-box-1>img {
        width: 100%;
    }

    .posts.in-columns {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media screen and (min-width: 767px) {
    .post-image-box {
        height: 20vw;
    }
}

@media screen and (min-width: 600px) {
    .post-image-box {
        height: 20vw;
    }
}
</style>

<template>
<div>
    <PageTitle :heading="title"></PageTitle>
    <div class="posts in-columns">
        <div class="post-box-1" v-for="(item,index) in tournamentData" :key="index">
            <div class="post-image-box">
                <router-link :to="'/tournaments/'+item.id"><img :src="$imagesBaseUrl+item.logo"></router-link>

                <div class="overlay"></div>
                <div class="coins post-coins">
                    <img src="../assets/images/coin.png" alt="coin" />
                    <span class="coin-count">{{ item.earn_points}}</span>
                </div>
            </div>

            <div class="post-box-1-footer">
                <h5 class="post-title-1">
                    <router-link :to="'/tournaments/'+item.id">{{ item.name }}</router-link>
                </h5>
                <div class="middle-items">
                    <p class="post-description-1">
                        Bets open now
                        <span style="font-weight: bold;color: green">: {{ item.bets_open_now}}</span>
                    </p>
                    
                    <p v-if="user_id" class="post-description-1">
                        Matches missed
                        <span style="font-weight: bold;color: red">: {{ item.not_bet_events[0].not_bet_count}}</span>
                    </p>

                    <p class="post-description-1">
                        Matches Ended
                        <span style="font-weight: bold;color: blue">: {{ item.matches_ended}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'
export default {
    name: 'PostsColumns',
    components: {
        PageTitle
    },

    data: function () {
        return {
            tournamentData: [],
            title: '',
            user_id: this.$cookie.get("user_id"),
        }
    },
    methods: {
        getTournaments() {
            this.$http.get(this.$baseUrl + 'api/tournament/get', {
                params: {
                    user_id: this.user_id,
                }
            }).then((response) => {
                this.tournamentData = response.data.data
                if (response.data.data.length < 1)
                    this.title = "There are no current tournaments";
                else
                    this.title = "Tournaments";
                document.cookie = "diamonds=" + response.data.diamonds;
            })
        },
        getImgUrl(pet) {
            var images = require.context('../assets/images', false)
            return images('./' + pet)
        },
    },
    created() {
        this.getTournaments()
    }
}
</script>

<style scoped>
.posts.in-columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.post-box-1 {
    width: 100%;
    height: auto;
    background: #F4F6FA;
    border-radius: 16px;
    margin-top: 91px;
    position: relative;
}

.post-image-box a {
    height: 36vw;
    display: block;
}

.post-box-1 .post-image-box img {
    width: 100%;
    height: 100%;
    border-radius: 16px 16px 0 0;
    object-fit: cover;
    object-position: center;
}

.post-box-1 .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 99;
    background: linear-gradient(0deg, rgba(46, 36, 69, 0.2), rgba(46, 36, 69, 0.2));
    display: none;
}

.post-box-1-footer {
    text-align: left;
    padding: 10px 15px;
    width: 100%;
    min-height: 70px;
    border-radius: 0 0 16px 16px;
    background: #F4F6FA;
    z-index: 999;
}

.post-box-1-footer h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

h5.post-title-1 a {
    color: #2E2445;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
}

.post-box-1-footer p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #1B1626;
    opacity: 0.5;
}

.post-box-1 .coins.post-coins {
    border: 2px solid grey !important;
    position: absolute;
    top: 15px;
    right: 5px;
    border-radius: 50px;
    z-index: 999;
    background-color: #ffffff;
    padding: 4px 10px;
    min-width: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post-box-1 .coins.post-coins .coin-count {
    color: #FFB545;
    padding-left: 5px;
    min-width: 50%;
}

.home-page .post-box-1 .coins.post-coins img {
    max-width: 21px !important;
}

@media (min-width: 993px) {
    .post-box-1>img {
        width: 100%;
    }

    .posts.in-columns {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .post-box-1 {
        width: 48%;
    }

    .post-box-1>img {
        width: 100%;
    }

    .posts.in-columns {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media screen and (min-width: 767px) {
    .post-image-box a {
        height: 20vw;
    }
}

@media screen and (min-width: 600px) {
    .post-image-box a {
        height: 25vw;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .post-box-1[data-v-2f258fba] {
        width: 100% !important;
    }
}
</style>

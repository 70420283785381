<template>
    <div class="bet-page">
        <div class="header">
            <div class="back-btn">
               <a href="/"><img src="../assets/images/svg/arrow-left.svg" alt="icon"></a>
            </div>
            <div class="title">
                <p >Bet on your Team</p>
            </div>
        </div>

        <div class="container">
        <div class="row">
        <div class="col-12">
        <div class="team-bet-section">
            <div class="team-one-bet-section">
             <img class="fluid" src="../assets/images/svg/team-one.svg">
              <p class="team-name">Manchester United</p>
            </div>
             <div class="team-vs-bt-logo">
              <img class="fluid" src="../assets/images/svg/bet-page-vs.svg">
             </div>
              <div class="team-two-bet-section">
               <img class="fluid" src="../assets/images/svg/team-two.svg">
             <p class="team-name">liverPool</p>
            </div>
        </div>
        </div>
        </div>

         <div class="team-prizes">
         <div class="team-one-prize">
        <div class="team-one-prize-box">
        <span><img class="fluid" src="../assets/images/svg/coin.svg"></span>
        <span>65 Prize</span>
        </div>
        <div class="prize-radio"><input name="prize" type="radio" checked></div>
         </div>
         <div class="team-one-prize">
        <div class="team-one-prize-box">
        <span><img class="fluid" src="../assets/images/svg/coin.svg"></span>
        <span>65 Prize</span>
        </div>
        <div class="prize-radio" ><input name="prize" type="radio"></div>
         </div>
         </div>

         <div class="team-bet-section_date">
         <p>Wed, 31 Mar,2022 - 09:30 pm</p>
         <div class="team-bet-section_details">

         <div class="team-bet-section_details-title"><span>Double Your Prize</span>
         <span>
          <label class="toggle-control">
            <input type="checkbox" checked="checked">
            <span class="control"></span>
            </label>
         </span>
         </div>
         <div class="team-bet-section_details-desc">
         <p>if you win, you will get double of your prize, but if you lose, same amount of coin will be deducted.</p>
         </div>

         
         </div>
         </div class="bet-offer-section">  
            <div class="bet-offer-title">
            <span>You have placed your bet, you can change your bet by using a diamond</span>
            </div>
            <div class="bet-offer-conform">
            
           <div>
  <b-button v-b-modal.modalPopover>Change Bet</b-button>

  <b-modal ref="modalPopover" id="modalPopover" hide-footer hide-header-close=false ok-title="Confirm" cancel-title="Cancel" >
   <div class="modal-content-box">
        <p><img src="../assets/images/svg/diamand.svg" alt="icon"></p>
        <p>Change Bet Confirmation</p>
        <p>If you click<b> yes</b>, you will lose 
         <b>1 diamond</b></p>
       <div class="modal-bottom">
        <b-button class="mt-3" block @click="confirmBet">No</b-button>
        <b-button class="mt-3" block @click="cancelBet">Yes</b-button>
       </div>
    </div>
  </b-modal>
</div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'ChangeBet',
        data: function () {
            return {}
        },
        methods:{
            cancelBet() {
        this.$refs['modalPopover'].hide()
      },
      confirmBet() {
        this.$refs['modalPopover'].hide()
      },
        }
    }
</script>
<style scoped>
 
</style>

<template>
<div class="home-page tournaments">
    <Header></Header>
    <b-container fluid="sm">
        <div class="title-head">
            <div class="arrow-left">
                <a href="/"><span class="icon"><img src="../assets/images/svg/arrow-left-black.svg" alt="icon"></span></a>
            </div>
            <div class="page-title">
                <h4>Tournaments</h4>
            </div>
        </div>

        <div class="posts-my-tournament">
            <div v-if="tournamentLength">
            <div  class="post-box-1" v-for="(item,index) in tournamentData" :key="index">
                <div class="post-image-box">
                    <router-link :to="'/tournaments/'+item.tournament_id">
                        <img :src="$imagesBaseUrl+item.logo">
                    </router-link>

                    <div class="overlay"></div>
                    <!-- <div class="coins post-coins">
                        <img src="../assets/images/coin.png" alt="coin" />
                        <span class="coin-count">129</span>
                    </div> -->
                </div>

                <div class="post-box-1-footer">
                    <h5 class="post-title-1">
                        <router-link :to="'/tournaments/'+item.tournament_id">
                        {{ item.name }}
                        </router-link>
                    </h5>
                    <!-- <p class="post-description-1">you haven’t bet<span style="font-weight: bold;color: black"> 06 </span>
                        matches yet
                    </p> -->
                </div>
            </div>
            </div>
            <div v-else class="post-box-1 no-tournaments">
                You do not have any tournamnet yet
            </div>
        </div>
    </b-container>
</div>
</template>

<script>
import Header from '../components/Header'
import PageTitle from '../components/PageTitle.vue'
import PostsColumns from '../components/PostsColumns.vue'

export default {
    name: 'HomePage',
    props: {},
    components: {
        Header,
        PageTitle,
        PostsColumns
    },
    data: function () {
        return {
            tournamentData: {
                tournamentData: [],
            },
            userData: {
                userId: this.$cookie.get("user_id"),
            },
        }
    },
    methods: {
        getTournaments(userId) {
            this.$http.get(this.$baseUrl + 'api/tournament/get/user/tournament/' + userId).then((response) => {
                this.tournamentData = response.data.data;
            })
        },
    },
    computed:{

        tournamentLength(){
            return this.tournamentData.length;
        }

    },
    mounted() {
        this.getTournaments(this.userData.userId);

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.posts.in-columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.post-box-1 {
    width: 100%;
    height: 185px;
    background: #F4F6FA;
    border-radius: 16px;
    margin-top: 30px;
    position: relative;
    overflow: hidden;
}

.post-box-1 .post-image-box {
    width: 100%;
    height: 75%;
    overflow: hidden;
}

.post-box-1 img {
    width: 100%;

    border-radius: 16px 16px 0px 0px;
}

.post-box-1 .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 99;
    background: linear-gradient(0deg, rgba(46, 36, 69, 0.2), rgba(46, 36, 69, 0.2));
}

.post-box-1 img {
    width: inherit;
}

.post-box-1-footer {
    text-align: left;
    padding: 10px 0 0px 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25%;
    min-height: 70px;
    border-radius: 0 0 16px 16px;
    background: #F4F6FA;
    z-index: 999;
}

.post-box-1 {
    width: 100%;
    height: 185px;
    background: #F4F6FA;
    border-radius: 16px;
    margin-top: 30px;
    position: relative;
}

.post-box-1-footer h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

h5.post-title-1 a {
    color: #2E2445;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
}

.post-box-1-footer p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #1B1626;
    opacity: 0.5;
}

.post-box-1 .coins.post-coins {
    position: absolute;
    top: 15px;
    right: 30px;
    z-index: 999;
}

.post-box-1 .coins.post-coins .coin-count {
    color: #FFB545;
    padding-left: 5px;
}

.home-page .post-box-1 .coins.post-coins img {
    height: 21px;
    width: 21px !important;
}

.post-box-1 .coins.post-coins:before {
    content: '';
    width: 76px;
    height: 32px;
    background: #FFFFFF;
    opacity: 0.2;
    border-radius: 50px;
    position: absolute;
    top: -4px;
    left: -8px;
}

@media (min-width: 768px) and (max-width: 992px) {
    .post-box-1 {
        width: 48%;
    }

    .post-box-1>img {
        width: 100%;
    }

    .posts.in-columns {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media screen and (max-width: 767px) {
    .post-box-1 {
        height: 58vw;
    }

    .post-box-1 .post-image-box img {
        width: 100%;
        /*height: 100%; */
        object-fit: cover;
        object-position: center;
    }
}

@media (min-width: 993px) {
    .post-box-1 {
        width: 23%;
    }

    .post-box-1>img {
        width: 100%;
    }

    .posts.in-columns {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.tournaments .posts.in-columns {
    flex-direction: column !important;
}
.tournaments .posts.in-columns .post-box-1 {
    width: 100% !important;
}
</style>

<template>
<div class="home-page">
    <Header page="wallet"></Header>
    <b-container fluid="sm">
        <div class="profile-pages">

            <ul class="nav nav-tabs mt-4" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link" @click="$store.commit('setTab','history')" :class="$store.state.tab=='history'?'active':''" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="true">Summary</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="$store.commit('setTab','coins')" :class="$store.state.tab=='coins'?'active':''" id="coins-tab" data-toggle="tab" href="#coins" role="tab" aria-controls="coins" aria-selected="false">Coins</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="$store.commit('setTab','diamond')" :class="$store.state.tab=='diamond'?'active':''" id="diamond-tab" data-toggle="tab" href="#diamond" role="tab" aria-controls="diamond" aria-selected="false">Diamond</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="$store.commit('setTab','rm')" :class="$store.state.tab=='rm'?'active':''" id="rm-tab" data-toggle="tab" href="#rm" role="tab" aria-controls="rm" aria-selected="false">RM</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="mt-4 tab-pane fade" :class="$store.state.tab=='history'?'active show':''" id="history" role="tabpanel" aria-labelledby="history-tab">
                    <table v-if="wallet.length>0" class="table w-100 table-responsive wallet-table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Gained</th>
                                <th>Used</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in wallet" :key="item.id">
                                <td>{{ item.reward_type }}</td>
                                <td>{{ item.won }}</td>
                                <td>{{ item.lost }}</td>
                                <td>{{ item.total }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="no-data">
                        <h4>There is no data in your wallet</h4>
                    </div>
                </div>
                <div class="mt-4 tab-pane fade" :class="[$store.state.tab=='coins'?'active show':'']" id="coins" role="tabpanel" aria-labelledby="coins-tab">
                    <table v-if="coins.length>0" class="table w-100 table-responsive wallet-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in coins" :key="item.id">

                                <td>{{moment(item.created_at).format("YYYY-MM-DD")}}</td>
                                <td>{{ item.description }}</td>
                                <td class="text-success" v-if="item.won">+{{ item.won }}</td>
                                <td class="text-danger" v-else-if="(item.lost>0)">-{{ item.lost }}</td>
                                <td v-else>{{ item.lost }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="no-data">
                        <h4>There is no data in your wallet</h4>
                    </div>
                </div>
                <div class="mt-4 tab-pane fade" :class="[$store.state.tab=='diamond'?'active show':'']" id="diamond" role="tabpanel" aria-labelledby="diamond-tab">
                    <table v-if="diamonds.length>0" class="table w-100 table-responsive wallet-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in diamonds" :key="item.id">
                                <td>{{moment(item.created_at).format("YYYY-MM-DD")}}</td>
                                <td>{{ item.description }}</td>
                                <td class="text-success" v-if="item.won">+{{ item.won }}</td>
                                <td class="text-danger" v-if="item.lost">-{{ item.lost }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="no-data">
                        <h4>There is no data in your wallet</h4>
                    </div>
                </div>
                <div class="mt-4 tab-pane fade" :class="[$store.state.tab=='rm'?'active show':'']" id="rm" role="tabpanel" aria-labelledby="rm-tab">
                    <table v-if="rms.length>0" class="table w-100 table-responsive wallet-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in rms" :key="item.id">
                                <td>{{moment(item.created_at).format("YYYY-MM-DD")}}</td>
                                <td>{{ item.description }}</td>
                                <td class="text-success" v-if="item.won">+{{ item.won }}</td>
                                <td class="text-danger" v-if="item.lost">-{{ item.lost }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="no-data">
                        <h4>There is no data in your wallet</h4>
                    </div>
                </div>
            </div>

        </div>
    </b-container>
</div>
</template>

<script>
import Header from '../components/Header.vue'
import moment from 'moment'

export default {
    name: 'Wallet',
    props: {},
    components: {
        Header,
    },
    data: function () {
        return {
            wallet: [],
            coins: [],
            diamonds: [],
            rms: [],
            moment: moment
        }
    },
    methods: {

        walletData(user_id) {
            this.$http.get(this.$baseUrl + 'api/userWalletData/' + user_id).then((response) => {
                this.wallet = response.data.history;
                this.coins = response.data.coins;
                this.diamonds = response.data.diamonds;
                this.rms = response.data.rms;
            })
        }

    },
    mounted() {
        this.walletData(this.$cookie.get("user_id"));
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.profile-pages .list-group {
    padding: 20px 8px 8px;
}

.profile-pages .list-group-item .arrow-right img {
    width: 20px;
    height: 20px;
}

.profile-pages .list-group-item {
    position: relative;
}

.profile-pages .list-group-item .arrow-right {
    position: absolute;
    right: 10px;
}

.profile-pages .list-group .icon {
    margin-right: 22px;
}

a.list-group-item.list-group-item-action {
    padding: 16px;
    border: 0px;
    background: rgba(46, 36, 69, 0.24);
    background-color: rgb(250 250 251);
    border-radius: 8px;
    margin-bottom: 16px;
    color: rgba(27, 22, 38, 0.6);
    height: 56px
}

.profile-pages .list-group a {
    text-decoration: none;
    color: #7471a0;
}

ul#myTab {
    flex-wrap: nowrap !important;
}
</style>

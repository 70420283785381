<template>
<div class="home-page">
    <Header page="wallet"></Header>
    <b-container fluid="sm">
        <div class="how-it-work-page">

            <p class="how-it-work-heading">How it works</p>
            <p class="heading">Join on any available tournament in homepage.</p>
            <div class="upcoming-matches">
                <img src="../../src/assets/images/upcoming-matches.png" alt="upcominpg matches">
            </div>

            <p class="heading mt-5">Player can bet for free on each match before match start.</p>

            <div class="bet-now">
                <img src="../../src/assets/images/bet-now.png" alt="bet now matches">
            </div>

            <p class="heading mt-5">You can change your bet with 1 Diamond within 30 min after match starts.
                (Diamonds earned from inviting player)</p>

            <div class="bet-now">
                <img src="../../src/assets/images/change-bet.png" alt="bet now matches">
            </div>

            <h1 class="rms">10 Coins = RM 1</h1>

            <p class="heading mt-5">Exchange coins to official game credits on "Rewards" in menu (x10 Turnover). 
                After you submit exchange, we’ll contact you via email.</p>

            <div class="rewards-redeem">
                <img src="../../src/assets/images/rewards.png" alt="rewards">
            </div>

        </div>
    </b-container>
</div>
</template>

<script>
import Header from '../components/Header.vue'

export default {
    name: 'HowItWorks',
    props: {},
    components: {
        Header,
    },
    data: function () {
        return {
            
        }
    },
    methods: {

    },
    mounted() {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.profile-pages .list-group {
    padding: 20px 8px 8px;
}

.profile-pages .list-group-item .arrow-right img {
    width: 20px;
    height: 20px;
}

.profile-pages .list-group-item {
    position: relative;
}

.profile-pages .list-group-item .arrow-right {
    position: absolute;
    right: 10px;
}

.profile-pages .list-group .icon {
    margin-right: 22px;
}

a.list-group-item.list-group-item-action {
    padding: 16px;
    border: 0px;
    background: rgba(46, 36, 69, 0.24);
    background-color: rgb(250 250 251);
    border-radius: 8px;
    margin-bottom: 16px;
    color: rgba(27, 22, 38, 0.6);
    height: 56px
}

.profile-pages .list-group a {
    text-decoration: none;
    color: #7471a0;
}

ul#myTab {
    flex-wrap: nowrap !important;
}
</style>

<template>
<div>
    <TournamentHeader :header="header"></TournamentHeader>
    <section>
        <b-container fluid>
            <div class="tab-nav">
                <b-tabs pills>
                    <b-tab title="Upcoming" active @click="getEvents">
                        <b-card-text v-if="eventData">
                            <div class="upcoming-section" v-for="(group,groupIndex) in eventData" :key="groupIndex">
                                <p class="mb-2">Group: <span class="text-capitalize">{{ groupIndex }}</span></p>
                                <div class="display" v-for="(item,index) in group" :key="index">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="display-content">
                                                <div class="display-content-date"><span>Match {{ index+1 }}/{{ group.length }}</span></div>
                                                <div class="display-content-match-vs">
                                                    <div class="teams">
                                                        <span class="t1"><img class="fluid" :src="$imagesBaseUrl+item.team_one_logo" :title="item.team_one_name"></span>
                                                        <span class="t1">VS</span>
                                                        <span class="t2"><img class="fluid" :src="$imagesBaseUrl+item.team_two_logo" :title="item.team_two_name"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 right-col">
                                            <div class="display-content">
                                                <div class="display-content-date"><span>{{moment(item.date).format("ddd, Do MMM")}}, {{ item.time}}</span></div>
                                                <div class="display-content-match-vs">
                                                    <div class="display-content-bet" v-if="(check_match_status(item) == 1)">
                                                        <!-- <b-button pill :class="[ check_match_status(CurrentDate, item.date) !=true ? 'disabled' : '']" > -->

                                                        <router-link :to="'/bet/'+item.id">
                                                            <b-button :class="item.user_id!=null?'change-bet-btn':''" pill>{{ item.user_id!=null?'Change Bet':'Bet' }}</b-button>
                                                        </router-link>
                                                    </div>
                                                    <div class="display-content-bet" v-else-if="(check_match_status(item) == 2)">
                                                        <!-- <b-button pill :class="[ check_match_status(CurrentDate, item.date) !=true ? 'disabled' : '']" > -->
                                                            <b-button pill>Match Ongoing</b-button>
                                                    </div>
                                                    <div class="display-content-bet not-bet-btn" v-else>
                                                        <b-button pill class="disabled ">
                                                            <router-link :to="'/bet/'+item.id">You haven't Bet</router-link>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                        <b-card-text v-else>
                            <span class="not-data">
                                There is no upcoming match
                            </span>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="History" @click="getHistoryEvents">
                        <b-card-text>
                            <div class="top" v-if="eventHistoryData.length!=0">
                                <div class="upcoming-section" v-for="(item,index) in eventHistoryData" :key="index">
                                    <div class="display">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="display-content">
                                                    <div class="display-content-date"><span>Match {{ index+1}}/{{eventHistoryData.length }}</span></div>
                                                    <div class="display-content-match-vs">
                                                        <div class="teams">
                                                            <span><img class="fluid" :src="$imagesBaseUrl+item.teamone.logo" alt="icon"></span>
                                                            <!-- <span><img class="fluid" src="../assets/images/vs.png" alt="icon"></span> -->
                                                            <span>VS</span>
                                                            <span><img class="fluid" :src="$imagesBaseUrl+item.teamtwo.logo" alt="icon"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 right-col">
                                                <div class="display-content">
                                                    <div class="display-content-date"><span>{{moment(item.date).format("ddd, Do MMM")}}, {{ item.time}}</span></div>
                                                    <div class="display-content-match-vs">
                                                        <div class="display-content-bet">
                                                            <div class="coins">
                                                                <div v-if="item.isBet==0">
                                                                    <div class="display-content-bet not-bet">
                                                                        <b-button pill>You haven't bet</b-button>
                                                                    </div>
                                                                </div>
                                                                <div v-else-if="(item.isWinner) && (item.isDoublePrize ==1)">
                                                                    <div class="match-result-coins">
                                                                        <span class="coin-count"> Won</span>
                                                                        <span class="coin-count"> 2x</span>
                                                                    </div>
                                                                </div>
                                                                <div v-else-if="(item.isWinner) && (item.isDoublePrize ==0)">
                                                                    <div class="match-result-coins">
                                                                        <span class="coin-count"> Won</span>
                                                                        <span class="coin-count"> 1x</span>
                                                                    </div>
                                                                </div>
                                                                <div v-else-if="(item.isWinner==0) && (item.isDoublePrize ==1)">
                                                                    <div class="match-result-coins lose">
                                                                        <span class="coin-count"> Lose</span>
                                                                        <span class="coin-count"> 2x</span>
                                                                    </div>
                                                                </div>
                                                                <div v-else-if="(item.isWinner==0) && (item.double_prize ==0)">
                                                                    <div class="match-result-coins lose">
                                                                        <span class="coin-count"> Lose</span>
                                                                        <span class="coin-count"> 1x</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="no-history">
                                <h4>There is no history</h4>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Result" @click="getResultEvents">
                        <b-card-text>
                            <div class="has-result" v-if="eventResults.length>0">
                                <div class="result-content">
                                    <div class="result-heading">
                                        <span>1st Team</span>
                                        <span>2st Team</span>
                                        <span>Prize</span>
                                    </div>
                                    <div class="result-content-box" v-for="(item, index) in eventResults" :key="index">
                                        <div class="result-content-scores-box-one">
                                            <div class="result-content-scores-team-logo">
                                                <img class="fluid" :src="$imagesBaseUrl+item.team_one_logo" alt="icon">
                                            </div>
                                            <div class="result-content-scores-team-one-result">
                                                <!-- <span>3 - 1</span> -->
                                            </div>
                                        </div>

                                        <div class="result-content-scores-box-two">
                                            <div class="result-content-scores-team-logo">
                                                <img class="fluid" :src="$imagesBaseUrl+item.team_two_logo" alt="icon">
                                            </div>

                                            <div v-if="(item.result > 0) && (item.result == item.bet_team_id) && (item.double_prize ==1)">
                                                <div class="result-content-scores-team-two-result ">
                                                    <div class="match-result-coins">
                                                        <span class="coin-count"> + {{ (item.points)}}</span>
                                                        <span class="coin-count"> 2x</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else-if="(item.result > 0) && (item.result == item.bet_team_id) && (item.double_prize ==0)">
                                                <div class="result-content-scores-team-two-result ">
                                                    <div class="match-result-coins">
                                                        <span class="coin-count"> + {{ (item.points)}}</span>
                                                        <span class="coin-count"> 1x</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else-if="(item.result > 0) && (item.result != item.bet_team_id) && (item.double_prize ==0)">
                                                <div class="result-content-scores-team-two-result lose">
                                                    <div class="match-result-coins">
                                                        <span class="coin-count"> {{ (item.points)}}</span>
                                                        <span class="coin-count"> 1x</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else-if="(item.result > 0) && (item.result != item.bet_team_id) && (item.double_prize ==1)">
                                                <div class="result-content-scores-team-two-result lose">
                                                    <div class="match-result-coins">
                                                        <span class="coin-count"> {{ (item.points)}}</span>
                                                        <span class="coin-count"> 2x</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else-if="item.result ==0">
                                                <div class="result-content-scores-team-one-result ">
                                                    <span class="coin-count"> 0 </span>
                                                </div>
                                            </div>

                                            <div v-else>
                                                <div class="result-content-scores-team-two-result ">
                                                    <div class="match-result-coins lose">
                                                        <span class="coin-count"> {{ (item.points)}}</span>
                                                        <span class="coin-count"> 2x</span>
                                                        {{ item.bet_team_id }}<br>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div v-else class="no-result">
                                <h3>There is no result for you</h3>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Statistics" @click="getStatisticEvents">
                        <b-card-text>
                            <div class="statistic-section">
                                <div class="statistic-section-title">
                                    <h3>My Statistics</h3>
                                </div>
                                <!-- <div class="statistic-section-setting">
                                    <span><img class="fluid" src="../assets/images/svg/settingstati.svg" alt="icon"></span>
                                    <span>Last 28 days</span>

                                </div> -->
                            </div>
                            <div class="statistic-section-main">
                                <div class="statistic-section-content">
                                    <p>{{eventStatistic.total_bet_matches}}/{{eventStatistic.total_tournament_matches}}</p>
                                    <p>Played / Total Matches</p>
                                </div>
                                <div class="statistic-section-content section-content-second">
                                    <p>{{ avg_win }}%</p>
                                    <p>Avg Win</p>
                                </div>

                            </div>
                            <div class="statistic-section-main">
                                <div class="statistic-section-content">
                                    <p>{{ eventStatistic.earn_points !='' ? eventStatistic.earn_points : 0}}</p>
                                    <p>Total Earned Points</p>
                                </div>
                                <div class="statistic-section-content section-content-second section-content-minus">
                                    <p>{{eventStatistic.deducted_points !="" ? eventStatistic.deducted_points :0 }}</p>
                                    <p>Total Points Deducted</p>
                                </div>

                            </div>
                            <div class="statistic-section-main">
                                <div class="statistic-section-content">

                                    <p>{{ eventStatistic.win_matches }}/{{eventStatistic.double_prize_bet}}</p>
                                    <p>Win / Double Prize Bet</p>
                                </div>
                                <div class="statistic-section-content section-content-second">
                                    <p>{{ double_bet_win }}%</p>
                                    <p>Double Prize Bet Win</p>
                                </div>

                            </div>

                            <div class="statistic-section-content-top-team">
                                <p>Top Teams You Bet For</p>
                                <div class="tatistic-section-content-top-team-logos">
                                    <span v-for="(item,index) in eventStatistic.top_bet_teams" :key="index">
                                        <img class="fluid" :src="$imagesBaseUrl+item.logo" alt="icon" width="41" height="41">
                                    </span>
                                </div>
                            </div>

                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </div>
        </b-container>
    </section>
</div>
</template>

<script>
import moment from "moment";
import TournamentHeader from '../components/TournamentHeader.vue'
export default {
    name: 'TournamentsPage',
    components: {
        TournamentHeader,
    },
    data: function () {
        return {
            header: {
                title: '',
                points: '',
                not_bet_events: '',
                total_matches: '',
                matches_ended:'',
                bets_open_now:'',
                matches_missed:'',
                diamonds: '',
                image: '',
            },
            eventData: [],
            eventHistoryData: [],
            eventResults: [],
            eventStatistic: [],
            avg_win: null,
            CurrentDate: [],
            double_bet_win: null,
            tournament_id: this.$route.params.id,
            user_id: this.$cookie.get("user_id"),
            moment: moment,

        }
    },
    methods: {
        getHeaderData() {
            this.$http.get(this.$baseUrl + 'api/tournament/get/header-data/' + this.tournament_id, {
                params: {
                    user_id: this.user_id,
                },
            }).then((response) => {
                this.header.title = response.data.title;
                this.header.points = response.data.points;
                this.header.image = response.data.logo;
                this.header.not_bet_events = response.data.not_bet_events;
                this.header.matches_ended = response.data.matches_ended;
                this.header.total_matches = response.data.total_matches;
            })
        },

        getEvents() {
            this.$http.get(this.$baseUrl + 'api/event/get/' + this.tournament_id, {
                params: {
                    user_id: this.user_id
                }
            }).then((response) => {

                this.eventData            = response.data.data;
                this.header.bets_open_now = response.data.bets_open_now;
            })
        },

        getHistoryEvents() {
            if(!this.user_id)
            this.$router.push('/login');
            this.$http.get(this.$baseUrl + 'api/event/history-event/' + this.tournament_id, {
                params: {
                    user_id: this.user_id
                }
            }).then((response) => {
                this.eventHistoryData = response.data.data
                console.log(this.eventHistoryData);
            })
        },

        getResultEvents() {
            if(!this.user_id)
            this.$router.push('/login');
            this.$http.get(this.$baseUrl + 'api/event/event-result/' + this.tournament_id, {
                params: {
                    user_id: this.user_id
                }
            }).then((response) => {
                this.eventResults = response.data.data;
            });
        },

        getStatisticEvents() {
            if(!this.user_id)
            this.$router.push('/login');
            this.$http.get(this.$baseUrl + 'api/event/event-statistic/' + this.tournament_id, {
                params: {
                    user_id: this.user_id,
                }
            }).then((response) => {
                this.eventStatistic = response.data;
                /*avg win */
                this.avg_win = this.avg_win_cal_avg(response.data);
                this.double_bet_win = this.double_bet_win_per(response.data);
                /** */
            });
        },

        avg_win_cal_avg(data) {
            var avg = null;
            if (data.win_matches > 0) {
                avg = ((data.win_matches / data.total_bet_matches) * 100);
            } else {
                avg = 0;
            }
            return avg.toFixed(0);
        },

        double_bet_win_per(data) {
            var avg = null;
            if (data.double_prize_win_per > 0 && data.double_prize_bet>0) {
                avg = ((data.double_prize_win_per / data.double_prize_bet) * 100);
            } else {
                avg = 0;
            }
            return avg.toFixed(0);
        },
        check_match_status(event) {

            var output;            
            var event_date = moment(event.date).add(30, 'minutes').format("MM-D-YYYY h:mm A");
            this.CurrentDate = moment().format("MM-D-YYYY h:mm A");

            var result = moment(this.CurrentDate).isBefore(event_date);

            if((result && event.user_id) || (result && !event.user_id))
            output=1;
            else if(!result && event.user_id)
            output=2;
            else
            output=0;

            return output;
        },

    },
    created() {
        this.getEvents();
        this.getHeaderData();
    }

}
</script>

<style>
.tab-nav .nav {
    justify-content: space-between;
    margin-bottom: 10px;

}

.tabs ul.nav li.nav-item .nav-link {
    color: rgba(27, 22, 38, .5);
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    padding: 1rem 0rem;
}

.tab-nav .upcoming-section p {
    font-weight: 600;
    line-height: 24px;
    font-size: 16px;
}

.upcoming-section .display {
    background: #F4F6FA;
    border-radius: 24px;
    min-height: 102.95px;
    max-width: 100%;
    width: 100%;
    padding: 14px;
    margin-bottom: 16px;
}

.upcoming-section .display-content-date span {
    display: block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: rgba(27, 22, 38, 0.5);
}

.display-content-match-vs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.display-content-bet button {
    width: fit-content;
    padding: 6px 25px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background-color: rgba(46, 36, 69, 1);
    color: #F4F6FA;
}

.display-content-bet button.btn-secondary:disabled,
.display-content-bet button.btn-secondary.disabled {
    font-size: 12px;
    font-weight: 500;
}

.display-content-bet.not-bet>button {
    width: fit-content;
    padding: 6px 11px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    background-color: rgba(46, 36, 69, 1);
    color: #F4F6FA;
    height: 32px;
    background: rgba(46, 36, 69, .5);
}

.display-content-bet {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.match-result-coins {
    padding: 0.3rem 0.3rem 0.3rem 0.2rem;
    background: rgba(88, 187, 72, 0.16);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.match-result-coins span:last-child {
    display: inline-block;
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 600;
    background: rgba(88, 187, 72, 1);
    color: #fff;
    padding-top: 1px;
}

.match-result-coins span:first-child {
    margin-left: 6px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(88, 187, 72, 1);
    padding-top: 1px;
}

/* .lose {
    background: rgba(248, 100, 54, 0.16);
} */

.lose span:first-child {
    color: rgba(248, 100, 54, 1);
}

.lose span:last-child {
    color: #ffff;
    background: rgba(248, 100, 54, 1);
}

.result-heading {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 40px;
    align-items: center;
}

.result-heading span {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.result-heading:before {
    position: absolute;
    top: 0;
    left: -15px;
    background: #F5F4F6;
    content: "";
    width: 107.6%;
    height: 100%;
    z-index: -1;
    color: rgba(46, 36, 69, .8);
}

.result-content-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.result-content-scores-box-one,
.result-content-scores-box-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    height: 86px;
    padding: 0px 20px 0px 0px;
}

.result-content-scores-box-two {
    padding: 0px 0px 0px 20px;
}

.result-content-scores-team-one-result span {
    display: block;
    background: rgba(46, 36, 69, 0.16);
    width: 56px;
    height: 32px;
    border-radius: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    color: #2E2445;
}

.result-content-scores-box-one.second {
    padding: 0px 0px 0px 20px;
}

.display-content-bet a {
    color: #fff;
    text-decoration: none;
}

.statistic-section {
    display: flex;
    justify-content: space-between;
}

.statistic-section-title h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2E2445;
}

.statistic-section-setting span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: #2E2445;
    margin: 0 7px;
    display: inline-block;
}

.statistic-section-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.statistic-section-content {
    width: 50%;
    padding: 13px 10px;
    background: #FFFFFF;
    box-shadow: 2px 2px 32px rgb(0 0 0 / 8%);
    border-radius: 12px;
    margin-right: 6px;

}

.section-content-second {
    margin-right: 0px;
    margin-left: 6px;
}

.statistic-section-content p:first-child .section-content-minus {
    color: #E4351D;
}

.statistic-section-content p:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #2E2445;
    margin-bottom: 0;
}

.statistic-section-content.section-content-minus p:first-child {
    color: #E4351D;
}

.statistic-section-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(46, 36, 69, .5);
    margin-bottom: 0;
}

.statistic-section-content-top-team {
    margin-top: 34px;
}

.statistic-section-content-top-team p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2E2445;
}

.tatistic-section-content-top-team-logos span {
    display: inline-block;
    margin-right: 8px;
}

.result-content-scores-team-logo {
    height: 41px;
    width: 41px;
}

.result-content-scores-team-logo img {
    width: 100%;
    height: 100%;
}

.not-bet-btn button {
    padding: 6px 13px;
}

.teams {
    align-items: center;
}
</style>

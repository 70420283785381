<template>
<div class="tournament-header">
    <img :src="$imagesBaseUrl+header.image">
    <b-container fluid="sm" class="header-content">
        <div class="header-bar">
            <div class="coin">
                <div>
                    <router-link :to="'/'"><img src="../assets/images/svg/arrow-left.svg" alt="icon"></router-link>
                </div>
            </div>

            <div class="coins">
                <div class="coins post-coins">
                    <router-link to="/wallet" style="text-decoration: none;">
                        <img src="../assets/images/svg/coin.svg" alt="icon">
                        <span class="coin-count">{{ header.points }}</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="header-content-title">
            <p class="main-title">{{ header.title }}</p>
            <p class="sub-title bets-open-now">Bets open now {{ header.bets_open_now }}</p>
            <p class="sub-title matches-ended">Matches Ended {{ header.total_matches-header.bets_open_now }}</p>
            <p class="sub-title matches-missed">Matches missed {{ header.not_bet_events }}</p>
            <!-- <p v-if="header.not_bet_events" class="sub-title">you haven't bet  {{ header.not_bet_events }} matches yet</p> -->
        </div>

    </b-container>
</div>
</template>

<script>
export default {
    name: 'TournamentHeader',
    props: ['header'],
    methods: {
        endedMatches(header) {
            return header.total_matches - header.bets_open_now;
        }
    }
}
</script>

<style scoped>
.header-bar .coins .post-coins {
    background: #ffb54554;
    border-radius: 50px;
    padding: .3rem 1rem .3rem .3rem;

}

.coin-count {
    color: #FFB545;
    padding-left: 1px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.tournament-header {
    width: 100%;
    border-radius: 0px 0px 24px 24px;
    position: relative;
}

.tournament-header img {
    width: inherit;
}

.tournament-header .header-content {
    position: absolute;
    top: 0;
}

.tournament-header.profile-header {
    min-height: 322px;
}

.header-bar {
    padding-top: 60px;
    display: flex;
    justify-content: space-between;
}

.header-bar .coin,
.header-bar .coins {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header-content-title {
    text-align: center;
    color: white;
}

.header-content-title .main-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-top: 16px;
    margin-bottom: 0px;
}

.header-content-title .sub-title {
    background-color: #ffb54554;
    border-radius: 50px;
    display: inline-block;
    padding: 2px 15px;
    font-size: 12px;
    margin: 5px !important;
}
</style>
